import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractEntityGridColumnComponent } from '@entity-framework/entity-grid/state/abstract-entity-grid-column.component';
import { BureauPaymentListItem } from '@app/bureau/payments/models/bureau-payment-list-item';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bureau-payments-status-column',
  template: `
    <div class="row" tid="payments-status-column">
      <div class="col">
        @if (listItem.status === 'NotAuthorised' || listItem.status === 'New') {
          <mat-select
            [value]="selectedStatus(listItem.status)"
            tid="payment-status"
            [class]="'status-item status-' + listItem.status?.toLowerCase()"
            (selectionChange)="confirmStatusChange($event, listItem)"
          >
            <mat-option
              [hidden]="status === selectedStatus(listItem.status)"
              *ngFor="let status of this.statusToDropdownOptions(listItem.status)"
              [value]="status"
            >
              {{ status }}
            </mat-option>
          </mat-select>
        } @else {
          <label [class]="'status-item status-' + this.listItem.status?.toLowerCase()">{{ listItem.status }}</label>
        }
      </div>
    </div>
  `,
  styleUrl: '../../../bank-files/entity-grid/bureau-bank-file-status/bureau-bank-file-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BureauPaymentsStatusColumnComponent extends AbstractEntityGridColumnComponent<BureauPaymentListItem> {
  constructor(private router: Router) {
    super();
  }

  confirmStatusChange(event, listItem) {
    event.source.value = this.selectedStatus(listItem.status);
    this.router.navigate(['', { outlets: { modal: ['bureau', 'payments', 'dismiss', listItem.id] } }], {
      queryParamsHandling: 'preserve'
    });
  }

  selectedStatus(option: string): string {
    return option === 'New' ? 'New' : 'Not authorised';
  }

  statusToDropdownOptions(option: string) {
    let options = ['Dismiss'];
    options.push(this.selectedStatus(option));
    return options;
  }
}
